import React, { useState, useEffect } from "react";
import CtaButton from "./Buttons/Cta/CtaButton";

const Slideshow = () => {
  const [slides, setSlides] = useState<any[]>([]);
  const [currentSlide, setCurrentSlide] = useState([0]);

  const fetchServices = async () => {

    try {
      const url =
        "https://lkn36jhvm5.execute-api.eu-west-2.amazonaws.com/Prod/services";
      const response = await fetch(url);
      const slides = await response.json();
      setSlides(slides.data);
    } catch (ex) {
      console.log("Failed to fetch services");
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <div>
      {Object.keys(slides).map((key: any) => (
        <div
          id={key}
          className={`flex lg:flex-row flex-col items-center space-x-20 px-16 lg:max-w-full md:max-w-md  ${currentSlide != key ? "hidden" : ""
            }`}
          key={key}
        >
          <div className=" text-white basis-1/3 z-10 lg:order-first md:order-last">
            <span className="text-2xl font-thin">
              {slides[key].service_title}
            </span>
            <h2 className="font-bold text-2xl">{slides[key].service_name}</h2>
            <p className="mt-7 mb-7 block">{slides[key].service_desc}</p>
            <div className="mt-8">
              <CtaButton url={slides[key].service_url} text="Meer weten?" />
            </div>
          </div>
          <div className="z-10">
            <img src={slides[key].image} alt="" className="md:max-h-[70%]" />
          </div>
        </div>
      ))}

      <div className="px-16 absolute bottom-2 z-20">
        {Object.keys(slides).map((key: any) => (
          <button
            onClick={() => setCurrentSlide(key)}
            className={`cursor-pointer rounded transition-all h-2 inline-block mr-3 ${key == currentSlide ? "w-8 bg-white" : "w-2 bg-blue-900"
              }`}
            key={key}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
