exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-[clientid]-forgot-password-tsx": () => import("./../../../src/pages/auth/[clientid]/forgot-password.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-forgot-password-tsx" */),
  "component---src-pages-auth-[clientid]-index-tsx": () => import("./../../../src/pages/auth/[clientid]/index.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-index-tsx" */),
  "component---src-pages-auth-[clientid]-login-tsx": () => import("./../../../src/pages/auth/[clientid]/login.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-login-tsx" */),
  "component---src-pages-auth-[clientid]-mfa-setup-tsx": () => import("./../../../src/pages/auth/[clientid]/mfa-setup.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-mfa-setup-tsx" */),
  "component---src-pages-auth-[clientid]-mfa-tsx": () => import("./../../../src/pages/auth/[clientid]/mfa.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-mfa-tsx" */),
  "component---src-pages-auth-[clientid]-new-password-required-tsx": () => import("./../../../src/pages/auth/[clientid]/new-password-required.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-new-password-required-tsx" */),
  "component---src-pages-auth-[clientid]-password-reset-tsx": () => import("./../../../src/pages/auth/[clientid]/password-reset.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-password-reset-tsx" */),
  "component---src-pages-auth-[clientid]-reset-password-tsx": () => import("./../../../src/pages/auth/[clientid]/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-[clientid]-reset-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

