import React, { FC } from "react";

type Props = {
  employee: object | any;
};

const EmployeeThumb: FC<Props> = ({ employee }) => {
  return (
    <li className="group" key={employee.emp_name}>
      <img
        src={`https://cmctelecom.be/${employee.emp_img}`}
        alt={employee.emp_name}
        className={` rounded-full ring-2 p-0.5 ring-gray-300 lg:w-10 lg:h-10 h-8 w-8 hover:scale-125 transition-all ${
          employee.emp_status ? "ring-green-400" : "ring-orange-300"
        }`}
      />
      <span className="group-hover:opacity-100 bg-black text-white font-semibold text-sm px-2 absolute mt-2 opacity-0 transition-opacity">
        {employee.emp_name}
      </span>
    </li>
  );
};

export default EmployeeThumb;
