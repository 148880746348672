import React, { useState, useEffect } from "react";
import EmployeeThumb from "./EmployeeThumb";

const EmployeeThumbs = () => {
  const [employees, setEmployees] = useState<any[]>([]);

  const fetchEmployees = async () => {
    const url = "https://puve56837j.execute-api.eu-west-2.amazonaws.com/Prod";
    const response = await fetch(url);
    const employees = await response.json();

    setEmployees(employees.data);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <ul className="ml-auto flex items-center space-x-2 mb-5">
      {Object.keys(employees).map((key: any) => (
        <EmployeeThumb employee={employees[key]} key={key} />
      ))}
    </ul>
  );
};

export default EmployeeThumbs;
