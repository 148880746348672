import React, { FC } from "react";

type Props = {
  url: string;
  text: string;
};

const CtaButton: FC<Props> = ({ url, text }) => {
  return (
    <a
      className="text-blue-700 inline-block bg-white px-6 py-2 font-bold rounded-xl hover:opacity-95 hover:rounded-lg transition-all"
      href={url}
    >
      {text}
    </a>
  );
};

export default CtaButton;
